import theme from "../theme";
import * as React from "react";
import { styled } from "@mui/material/styles";
import classes from "../styles/components/productivityReport";
import { BrowserView, MobileView } from "react-device-detect";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControlLabel, Checkbox} from "@mui/material";
import { blue } from '@mui/material/colors';
import { isMobile } from "react-device-detect";

class LineProductivityReport extends React.Component {
  constructor() {
    super();
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      width: 0,
      height: 0,
      tableView: false,
      showFullLinePath: false
    };
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if (window.innerWidth < 717) {
      this.setState({
        width: window.innerWidth,
        height: window.innerWidth / 4.4,
        tableView: true,
      });
    } else {
      this.setState({
        width: window.innerWidth,
        height: window.innerWidth,
        tableView: false,
      });
    }
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    // this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  //generates table headings for desktop
  generateRows() {
    const { data } = this.props;
    const {showFullLinePath} = this.state
    var lineData = data.line_data
    let elements = []
    let element
    let headingElement
    console.log("Line Data: ", lineData)
    headingElement = 
      <TableRow sx={{ backgroundColor: "#065f92" }}>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Line </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Items Packed</TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Kilograms Packed</TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Giveaway (kg) </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Giveaway (%) </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Items Per Minute </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Active Time (hh:mm) </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Average kg/h </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> % Underweight </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> % Overweight </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Total Waste (kg) </TableCell>
        <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> % Waste </TableCell>

    </TableRow>




    for (let i = 0; i < lineData.length; i++){
    element = 
            <TableRow key={i}>
                <TableCell align="center" style={classes(theme, isMobile).tableCell}> {(showFullLinePath ? lineData[i].line_path : lineData[i].line_id)} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].total_items).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].weight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].giveaway).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].giveaway_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].ipm).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].delta_time)} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].kg_per_hour).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].items_underweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].items_overweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].waste).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].waste_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
            </TableRow>
        elements.push(element)
      }
    this.rows = elements;
    this.headings = headingElement
  }

  //generates table headings for mobile
  generateMobileRows() {
    const { data } = this.props;
    const {showFullLinePath} = this.state
    var lineData = data.line_data
    let elements = []
    let element
    let headingElement

    for (let i = 0; i < data.length; i++){
        element = 
          <div style={{marginBottom:"2em"}}>
            <TableContainer component={Paper}>
              <Table sx={{ maxWidth: 310}}  size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow key={i} sx={{ backgroundColor: "#065f92" }}>
                    <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="center" colSpan={2}> {(showFullLinePath ? data[i].line_path : data[i].line)} </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Packed </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].total_items).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                </TableBody>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Kilograms Packed </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].weight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                </TableBody>   
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Giveaway (kg) </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].giveaway).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                </TableBody>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Giveaway (%) </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].giveaway_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                </TableBody>
                <TableBody>
                <TableCell style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} align="left"> Items Per Minute </TableCell>
                <TableCell style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} align="left"> {(data[i].ipm).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                </TableBody>            
              </Table>
            </TableContainer>
          </div>
        elements.push(element)
      }
    this.mobileRows = elements;
  }


  render() {
    const { data } = this.props;
    if (data){
      this.generateRows()
      this.generateMobileRows()
    }
    if (window.innerWidth < 717) {
      this.state.tableView = true;
    }

    return (
      <div style={{ marginLeft: "1em" }}>
        <FormControlLabel
            control = {<Checkbox
              sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}

              checked = {this.state.showFullLinePath}
              onChange={(e) => {
                this.setState({
                  showFullLinePath: e.target.checked,
                });
              }}
              inputProps = {{ 'aria-label': 'controlled' }} />}
            label={<span style={{ color:"white" }}> Show Full Line Path </span>} 

          />
        <BrowserView>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
              {this.headings}
              </TableHead>
              <TableBody>
                {this.rows}
              </TableBody>
            </Table>
          </TableContainer>
        </BrowserView>
        <MobileView>
          {this.mobileRows}
        </MobileView>
      </div>
    );
  }
}
export default styled(LineProductivityReport)({ theme });
