import React from "react";
import { Navigate } from "react-router-dom";
import theme from "../theme";
import { blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import classes from "../styles/components/modUser";
import { inputLabelClasses } from "@mui/material/InputLabel";
import {
  generateUUID,
  isEmpty,
  validateEmail,
  passwordFeedback,
  emailWarning,
  checkPassword,
  comparePassword,
  clone,
} from "../general/utils";
import { FAILED, LOADING, SUCCEEDED } from "../general/requestStates";
import {
  FormControl,
  FormControlLabel,
  Typography,
  Button,
  TextField,
  Grid,
  Radio,
  Autocomplete,
  RadioGroup,
  Checkbox,
} from "@mui/material";

class users extends React.Component {
  state = {
    usernames: [],
    selectedReports: [],
    selectAllReports: false,
    showPassword: false,
    showConfirmPassword: false,
    current: {
      item_setup_access: false,
      chosenUser: null,
      chosenCluster: null,
      sid: null,
      username: "",
      first_name: "",
      last_name: "",
      password_hash: "",
      confirmPassword: "",
      clusterName: null,
      cluster_sid: null,
      is_admin: false,
      user_active: true,
      report_active_threshold: 1,
      api_key: null,
      okta_user: "normal",
      overview_dashboard_access: false,
      order_and_line_status_access: false,
      station_and_operator_performance_access: false,
      station_and_operator_packing_distribution_access: false,
      station_and_operator_packing_speed_access: false,
      station_and_operator_giveaway_access: false,
      station_and_operator_waste_access: false,
      line_productivity_access: false,
      per_product_productivity_access: false,
      calibration_report_access: false,
    },
  };

  componentDidMount() {
    const { getAllUsernames, getClusterNames, getReportOptions } = this.props;
    getAllUsernames();
    getClusterNames();
    getReportOptions();
  }

  componentDidUpdate(prevProps) {
    const { usernames, getAllUsernamesRS, submitDataRS } = this.props;
    if (getAllUsernamesRS === SUCCEEDED && prevProps.getAllUsernamesRS === LOADING) {
      this.setState({ usernames });
    } else if (submitDataRS === SUCCEEDED && prevProps.submitDataRS === LOADING) {
      // After successful save, reload the page to reflect changes
      window.location.reload(false);
    }
  }

  renderRedirect = () => {
    const { user } = this.props;
    if (!isEmpty(user) || !user.is_admin) {
      return <Navigate to={"/"} />;
    }
  };

  handleUserSelect(event, value) {
    const { clusterNames } = this.props;
    const { current } = this.state;
    let selectedReportOptions = [];
    let selectedAllReportOptions = false;

    if (!isEmpty(value)) {
      current.okta_user = value.okta_user;
      current.sid = value.sid;
      current.username = value.username;
      current.first_name = value.first_name;
      current.last_name = value.last_name;

      const clusterIndex = clusterNames.findIndex((e) => e.sid === value.cluster_sid);
      if (clusterIndex === -1) {
        current.cluster_sid = null;
        current.chosenCluster = null;
      } else {
        current.cluster_sid = value.cluster_sid;
        current.chosenCluster = clusterNames.filter((e) => e.sid === value.cluster_sid)[0];
      }

      current.item_setup_access = value.item_setup_access;
      current.user_active = value.user_active;
      current.report_active_threshold = value.report_active_threshold;
      current.is_admin = value.is_admin;

      // Build selected reports list
      const reportMapping = [
        { accessKey: "overview_dashboard_access", sid: 1, name: "Overview Dashboard" },
        { accessKey: "order_and_line_status_access", sid: 2, name: "Order and Line Status" },
        { accessKey: "station_and_operator_packing_distribution_access", sid: 3, name: "Station and Operator Packing Distribution" },
        { accessKey: "station_and_operator_packing_speed_access", sid: 4, name: "Station and Operator Packing Speed" },
        { accessKey: "station_and_operator_giveaway_access", sid: 5, name: "Station and Operator Giveaway" },
        { accessKey: "line_productivity_access", sid: 6, name: "Line Productivity" },
        { accessKey: "per_product_productivity_access", sid: 7, name: "Per Product Productivity" },
        { accessKey: "calibration_report_access", sid: 8, name: "Calibration Report" },
        { accessKey: "station_and_operator_waste_access", sid: 9, name: "Station and Operator Waste" },
        { accessKey: "station_and_operator_performance_access", sid: 10, name: "Station and Operator Performance" },
      ];

      reportMapping.forEach((item) => {
        if (value[item.accessKey]) {
          selectedReportOptions.push({ sid: item.sid, name: item.name });
        }
      });

      if (selectedReportOptions.length === reportMapping.length) {
        selectedAllReportOptions = true;
      }
    } else {
      // Resetting user info if none selected
      current.okta_user = "normal";
      current.sid = null;
      current.username = "";
      current.first_name = "";
      current.last_name = "";
      current.chosenCluster = null;
      current.item_setup_access = false;
    }

    current.chosenUser = value;
    this.setState({ current, selectedReports: selectedReportOptions, selectAllReports: selectedAllReportOptions });
  }

  handleChange(field, event) {
    const { current } = this.state;
    current[field] = event.target.value;
    this.setState({ current });
  }

  handleCheckboxChange(field, event) {
    const { current } = this.state;
    current[field] = event.target.checked;
    this.setState({ current });
  }

  handleOktaChange(e) {
    const { current } = this.state;
    current.okta_user = e.target.value;
    this.setState({ current });
  }

  handleClusterSelect(key, value) {
    const { current } = this.state;
    if (value) {
      current.clusterName = value.name;
      current.cluster_sid = value.sid;
    } else {
      current.clusterName = null;
      current.cluster_sid = null;
    }
    current.chosenCluster = value;
    this.setState({ current });
  }

  handleReportSelect(key, value) {
    const { reportOptions } = this.props;
    const validReports = value.filter((report) =>
      reportOptions.some((option) => option.sid === report.sid)
    );
    this.setState({ selectedReports: validReports });
  }

  handleSelectAllReports(e) {
    const { reportOptions } = this.props;
    const value = e.target.checked;
    const selected = value ? [...reportOptions] : [];
    this.setState({ selectAllReports: value, selectedReports: selected });
  }

  determineDontSave() {
    const { current, selectedReports } = this.state;
    const { usernames } = this.props;

    const { username, first_name, last_name, password_hash, confirmPassword, sid, okta_user, chosenCluster } = current;
    const emailWarnings = this.emailDuplicate(username, current.chosenUser);

    // Conditions for saving
    const hasValidEmail = validateEmail(username) && emailWarnings === "";
    const hasCluster = !isEmpty(chosenCluster);
    const hasNames = first_name.length && last_name.length;

    // If existing user
    if (sid) {
      const noPasswordChange = !password_hash.length && !confirmPassword.length;
      const validPasswordChange = password_hash.length && confirmPassword.length && checkPassword(password_hash) === "ok" && confirmPassword === password_hash;

      if ((noPasswordChange && hasValidEmail && hasCluster && hasNames) ||
          (validPasswordChange && hasValidEmail && hasCluster && hasNames) ||
          (okta_user === "okta" && hasValidEmail && hasCluster)) {
        return false;
      }
    } else {
      // New user
      const validPasswordForNew = checkPassword(password_hash) === "ok" && confirmPassword === password_hash;
      if ((hasValidEmail && hasCluster && hasNames && validPasswordForNew) ||
          (okta_user === "okta" && hasValidEmail && hasCluster)) {
        return false;
      }
    }

    return true;
  }

  emailDuplicate(username, selectedUser) {
    const { usernames } = this.props;
    const usernameArray = usernames.map((u) => u.username);

    if (
      (selectedUser && username && selectedUser.username !== username && usernameArray.includes(username)) ||
      (!selectedUser && username && usernameArray.includes(username))
    ) {
      return (
        <Typography color="error" style={{ fontSize: "12px" }}>
          This Username already exists
        </Typography>
      );
    }

    return "";
  }

  setReportAccessFromSelected(selectedReports, data) {
    // Map report SID to field name
    const reportAccessMap = {
      1: "overview_dashboard_access",
      2: "order_and_line_status_access",
      3: "station_and_operator_packing_distribution_access",
      4: "station_and_operator_packing_speed_access",
      5: "station_and_operator_giveaway_access",
      6: "line_productivity_access",
      7: "per_product_productivity_access",
      8: "calibration_report_access",
      9: "station_and_operator_waste_access",
      10: "station_and_operator_performance_access",
    };

    Object.keys(reportAccessMap).forEach((key) => {
      const sid = parseInt(key, 10);
      data[reportAccessMap[sid]] = selectedReports.some((e) => e.sid === sid);
    });
  }

  handleSave() {
    const { submitData, usernames } = this.props;
    const { current, selectedReports } = this.state;

    const {
      sid,
      okta_user,
      username,
      first_name,
      last_name,
      password_hash,
      cluster_sid,
      is_admin,
      user_active,
      report_active_threshold,
      item_setup_access,
    } = current;

    let data = {};
    const existingUser = sid ? usernames.find((e) => e.sid === sid) : null;

    const baseData = {
      username,
      first_name,
      last_name,
      cluster_sid,
      is_admin,
      user_active,
      report_active_threshold,
      item_setup_access,
      okta_user,
    };

    this.setReportAccessFromSelected(selectedReports, baseData);

    // If editing an existing user, determine if changes are made
    if (existingUser) {
      const fieldsChanged = Object.keys(baseData).some((key) => baseData[key] !== existingUser[key]);

      // Check report fields as well
      const reportFields = [
        "overview_dashboard_access",
        "order_and_line_status_access",
        "station_and_operator_packing_distribution_access",
        "station_and_operator_packing_speed_access",
        "station_and_operator_giveaway_access",
        "line_productivity_access",
        "per_product_productivity_access",
        "calibration_report_access",
        "station_and_operator_waste_access",
        "station_and_operator_performance_access",
      ];

      const reportChanged = reportFields.some((field) => baseData[field] !== existingUser[field]);

      if (fieldsChanged || reportChanged) {
        data = { sid, ...baseData };
        if (password_hash && current.confirmPassword) {
          data.password_hash = password_hash;
        }
        submitData({ database: "ergopak_applications", table: "user", record: data });
      }
    } else {
      // New user
      data = { ...baseData };
      data.api_key = generateUUID();

      if (okta_user === "okta") {
        // For Okta user, no password is required
        submitData({ database: "ergopak_applications", table: "user", record: data });
      } else {
        // Normal user requires a password
        data.password_hash = password_hash;
        submitData({ database: "ergopak_applications", table: "user", record: data });
      }
    }
  }

  handleCancel() {
    const { current } = this.state;
    current.okta_user = "normal";
    current.username = "";
    current.first_name = "";
    current.last_name = "";
    current.password_hash = "";
    current.confirmPassword = "";
    current.chosenCluster = null;
    current.chosenUser = null;
    this.setState({ current, selectedReports: [], selectAllReports: false });
  }

  render() {
    const { clusterNames, reportOptions, submitDataRS, user } = this.props;
    const { current, selectedReports, showPassword, showConfirmPassword, usernames } = this.state;
    const { username, first_name, last_name, password_hash, confirmPassword, chosenUser } = current;

    const dropdownLabel = username ? "User to Modify" : "Select a user to modify";
    const usernameLabel = current.username ? "User name (must be an email address)" : "or start typing to create a new user";
    const passwordWarning = passwordFeedback(password_hash);
    const emailFeedback = emailWarning(current.username);
    const emailWarnings = this.emailDuplicate(current.username, current.chosenUser);
    const dontSave = this.determineDontSave();

    let passwordMatch = "";
    if (!comparePassword(password_hash, confirmPassword) && password_hash) {
      passwordMatch = (
        <Typography color="error" style={{ fontSize: "12px" }}>
          Passwords don't match
        </Typography>
      );
    }

    let message = "";
    if (submitDataRS === SUCCEEDED) {
      message = (
        <Typography style={{ color: "white", margin: theme.spacing(2) }}>
          User Saved!
        </Typography>
      );
    }

    const redirect = isEmpty(user) || user.is_admin ? "" : <Navigate to="/home" replace={true} />;

    return (
      <div>
        <div style={classes(theme, isMobile).mainCard}>
          <Grid>
            <Grid container direction="row" alignItems="flex-end">
              <div>
                <Autocomplete
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  style={isMobile ? { ...classes(theme, isMobile).filterField } : classes(theme, isMobile).filterField}
                  options={this.state.usernames}
                  getOptionLabel={(e) => e.username}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={current.chosenUser}
                  hidden={current.username && isEmpty(current.chosenUser)}
                  renderInput={(params) => <TextField {...params} label={dropdownLabel} variant="outlined" />}
                  onChange={this.handleUserSelect.bind(this)}
                />
              </div>

              <div>
                <TextField
                  inputProps={{ style: { color: "white" } }}
                  InputLabelProps={{
                    sx: {
                      color: "#FFFFFF",
                      [`&.${inputLabelClasses.shrink}`]: { color: "#FFFFFF" },
                    },
                  }}
                  variant="standard"
                  label={usernameLabel}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).usernameField }
                      : isEmpty(chosenUser) && !username
                      ? { ...classes(theme, isMobile).filterField, width: theme.spacing(23) }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  value={username}
                  onChange={this.handleChange.bind(this, "username")}
                />
              </div>
            </Grid>

            <Grid container direction="row">
              <div
                style={
                  isMobile
                    ? { marginLeft: "4em", marginTop: "-0.5em" }
                    : isEmpty(chosenUser) && username
                    ? { marginLeft: "1em", marginTop: "-0.5em" }
                    : { marginLeft: "25em", marginTop: "-0.5em" }
                }
              >
                {emailFeedback}
                {emailWarnings}
              </div>
            </Grid>

            <Grid container direction="row" alignItems="left">
              <div hidden={!username}>
                <TextField
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  type="text"
                  label={"First Name"}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).filterField }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  value={first_name}
                  onChange={this.handleChange.bind(this, "first_name")}
                />
              </div>

              <div hidden={!username}>
                <TextField
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  type="text"
                  label={"Last Name"}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).filterField }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  value={last_name}
                  onChange={this.handleChange.bind(this, "last_name")}
                />
              </div>
            </Grid>

            <div
              style={
                isMobile
                  ? { marginBottom: "1em", marginTop: "-0.5em" }
                  : { marginLeft: "1em", marginBottom: "1em", marginTop: "-0.5em" }
              }
            >
              <FormControl
                component="fieldset"
                style={classes(theme, isMobile).bulletBox}
                sx={{ display: !username ? "none" : null }}
              >
                <RadioGroup
                  direction="row"
                  aria-label="okta_user"
                  name="okta_user"
                  value={current.okta_user}
                  onChange={this.handleOktaChange.bind(this)}
                  sx={{ display: username === "" ? "none" : null }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="left"
                    sx={{ display: username === "" ? "none" : null }}
                  >
                    <Typography style={classes(theme, isMobile).bullet2}>Account Type:</Typography>
                    <FormControlLabel
                      sx={{ display: username === "" ? "none" : null }}
                      value={"normal"}
                      control={
                        <Radio
                          style={{ marginLeft: "1em" }}
                          sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }}
                        />
                      }
                      label={<span style={{ color: "white" }}> Normal </span>}
                    />
                    <FormControlLabel
                      sx={{ display: username === "" ? "none" : null }}
                      value={"okta"}
                      control={<Radio sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }} />}
                      label={<span style={{ color: "white" }}> Okta </span>}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </div>

            <Grid container direction="row" alignItems="left">
              <div hidden={!username || current.okta_user === "okta"}>
                <TextField
                  type={showPassword ? "string" : "password"}
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).filterField }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  onChange={this.handleChange.bind(this, "password_hash")}
                  label={"Password"}
                  value={current.password_hash}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="showClosed"
                      sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }}
                    />
                  }
                  checked={showPassword}
                  label={<span style={{ fontSize: "0.8em", color: "white" }}> Show password </span>}
                  onChange={() => this.setState({ showPassword: !showPassword })}
                  style={{ ...classes(theme, isMobile).passwordCheckbox, marginTop: "1.5em" }}
                />
              </div>
            </Grid>

            <Grid container direction="row" alignItems="left">
              <div style={{ marginLeft: "1rem", marginTop: "-0.5rem" }}>{passwordWarning}</div>
            </Grid>

            <div hidden={!password_hash || checkPassword(password_hash) !== "ok" || !username || current.okta_user === "okta"}>
              <Grid container direction="row" alignItems="left">
                <TextField
                  type={showConfirmPassword ? "string" : "password"}
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).filterField }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  onChange={this.handleChange.bind(this, "confirmPassword")}
                  label={"Confirm Password"}
                  value={confirmPassword}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="showClosed"
                      sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }}
                    />
                  }
                  checked={showConfirmPassword}
                  label={<span style={{ fontSize: "0.8em", color: "white" }}> Show password </span>}
                  onChange={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                  style={classes(theme, isMobile).passwordCheckbox}
                />
              </Grid>
            </div>

            <Grid container direction="row" alignItems="left">
              <div style={{ marginLeft: "1rem", marginTop: "-0.5rem" }} hidden={!password_hash || checkPassword(password_hash) !== "ok"}>
                {passwordMatch}
              </div>
            </Grid>

            <Grid container direction="row" alignItems="left">
              <div hidden={!username}>
                <Autocomplete
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).filterField }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  options={clusterNames}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={current.chosenCluster}
                  renderInput={(params) => <TextField {...params} label={"User's Cluster"} variant="outlined" />}
                  onChange={this.handleClusterSelect.bind(this)}
                />
              </div>
            </Grid>

            <Grid container direction="row" alignItems="left">
              <div hidden={!username}>
                <Autocomplete
                  multiple
                  sx={{ ...classes(theme, isMobile).root, svg: { color: "#FFFFFF" } }}
                  style={
                    isMobile
                      ? { ...classes(theme, isMobile).filterField }
                      : { ...classes(theme, isMobile).filterField, width: theme.spacing(22) }
                  }
                  options={reportOptions}
                  getOptionLabel={(e) => e.name}
                  isOptionEqualToValue={(o, v) => o.sid === v.sid}
                  value={selectedReports}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={selectedReports.length > 1 ? "User's Reports" : "User's Report"}
                      variant="outlined"
                      sx={{ ...classes(theme, isMobile).root, div: { color: "#FFFFFF" }, path: { color: "#FFFFFF" } }}
                    />
                  )}
                  onChange={this.handleReportSelect.bind(this)}
                />
              </div>
              <div hidden={!username} style={{ marginTop: "1.4em" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }}
                      checked={this.state.selectAllReports}
                      onChange={this.handleSelectAllReports.bind(this)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<span style={{ fontSize: "0.8em", color: "white" }}> Select All Reports </span>}
                />
              </div>
            </Grid>

            <div style={isMobile ? { marginLeft: "0em" } : { marginLeft: "1em", marginTop: "-0.5em" }}>
              <FormControl
                component="fieldset"
                style={classes(theme, isMobile).bulletBox}
                sx={{ display: !username ? "none" : null }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }}
                      checked={current.item_setup_access}
                      onChange={this.handleCheckboxChange.bind(this, "item_setup_access")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<span style={{ color: "white" }}> Item Setup Accessibility </span>}
                />

                <RadioGroup
                  direction="row"
                  aria-label="user_active"
                  name="user_active"
                  value={current.user_active}
                  onChange={(e) => {
                    const val = e.target.value === "true" ? true : false;
                    const { current } = this.state;
                    current.user_active = val;
                    this.setState({ current });
                  }}
                  sx={{ display: username === "" ? "none" : null }}
                >
                  <Grid container direction="row" alignItems="left" sx={{ display: username === "" ? "none" : null }}>
                    <Typography style={classes(theme, isMobile).bullet}>User Status:</Typography>
                    <FormControlLabel
                      sx={{ display: username === "" ? "none" : null }}
                      value={true}
                      control={<Radio style={{ marginLeft: "1em" }} sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }} />}
                      label={<span style={{ color: "white" }}> Active </span>}
                    />
                    <FormControlLabel
                      sx={{ display: username === "" ? "none" : null }}
                      value={false}
                      control={<Radio sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }} />}
                      label={<span style={{ color: "white" }}> Cancelled </span>}
                    />
                  </Grid>
                </RadioGroup>

                <RadioGroup
                  direction="row"
                  aria-label="user_role"
                  name="is_admin"
                  value={current.is_admin}
                  onChange={(e) => {
                    const val = e.target.value === "true" ? true : false;
                    const { current } = this.state;
                    current.is_admin = val;
                    this.setState({ current });
                  }}
                  sx={{ display: username === "" ? "none" : null }}
                >
                  <Grid container direction="row" alignItems="left" sx={{ display: username === "" ? "none" : null }}>
                    <Typography style={classes(theme, isMobile).bullet}>User Role:</Typography>
                    <FormControlLabel
                      value={true}
                      control={<Radio style={{ marginLeft: "1em" }} sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }} />}
                      label={<span style={{ color: "white" }}> Admin </span>}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio style={{ marginLeft: "-0.1em" }} sx={{ color: blue[800], "&.Mui-checked": { color: blue[600] } }} />}
                      label={<span style={{ color: "white" }}>Client</span>}
                    />
                  </Grid>
                </RadioGroup>

                <Grid
                  style={{ marginTop: "1em" }}
                  container
                  direction="row"
                  alignItems="left"
                  sx={{ display: username === "" ? "none" : null }}
                >
                  <TextField
                    value={current.report_active_threshold}
                    inputProps={{ type: "number" }}
                    label={<span style={{ color: "white" }}>Active Threshold (minutes)</span>}
                    sx={{ ...classes(theme, isMobile).root, div: { color: "#FFFFFF" }, path: { color: "#FFFFFF" } }}
                    onChange={this.handleChange.bind(this, "report_active_threshold")}
                  />
                </Grid>
              </FormControl>
            </div>

            <div hidden={!username}>
              <Grid item>
                <Grid container direction="row">
                  <Button
                    style={classes(theme, isMobile).button}
                    variant="contained"
                    onClick={this.handleSave.bind(this)}
                    disabled={dontSave}
                  >
                    Save
                  </Button>
                  <Button
                    style={classes(theme, isMobile).button}
                    variant="contained"
                    onClick={this.handleCancel.bind(this)}
                  >
                    Cancel
                  </Button>
                  {message}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        {redirect}
      </div>
    );
  }
}

export default styled(users)({ theme });
