import theme from "../theme";
import * as React from "react";
import { styled } from "@mui/material/styles";
import classes from "../styles/components/productivityReport";
import { BrowserView, MobileView } from "react-device-detect";
import {Grid, FormControl, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControlLabel, Checkbox} from "@mui/material";
import { blue } from '@mui/material/colors';
import { isMobile } from "react-device-detect";

class stationOperatorPerformance extends React.Component {
    constructor() {
        super();
        this.updateDimensions = this.updateDimensions.bind(this);
        this.state = {
            width: 0,
            height: 0,
            tableView: false,
            showFullLinePath: false,
            showWorkerData: false
        };
    }


    updateDimensions() {
        if (window.innerWidth < 717) {
            this.setState({
                width: window.innerWidth,
                height: window.innerWidth / 4.4,
                tableView: true,
            });
        } 
        
        else {
            this.setState({
                width: window.innerWidth,
                height: window.innerWidth,
                tableView: false,
            });
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }


    //generates table headings for desktop
    generateRows() {
        const { data } = this.props;
        var lineData

        this.state.showWorkerData ? lineData = data.worker_data : lineData = data.line_data

        console.log(data)
        let elements = []
        let element
        let headingElement

 
        headingElement = 
            <TableRow sx={{ backgroundColor: "#065f92" }}>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Line Details </TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Scale Details </TableCell>

                {this.state.showWorkerData ? <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Operator Personal Number </TableCell> : null}
                {this.state.showWorkerData ? <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Operator Name and Surname </TableCell> : null}

                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Total Kilograms Packed</TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Active Time (hh:mm)</TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Average kg/h </TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> % Underweight </TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> % Overweight </TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> Total Waste (kg) </TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> % Waste </TableCell>
                <TableCell align="center" style={classes(theme, isMobile).tableHeadings}> First Packed </TableCell>
            </TableRow>
    

        for (let i = 0; i < lineData.length; i++) {
            element = 
                <TableRow key={i}>
                    <TableCell align="center" style={classes(theme, isMobile).tableCell}> {(this.state.showWorkerData ? lineData[i].line_id_detail : lineData[i].line_id)} </TableCell>
                    <TableCell align="center" style={classes(theme, isMobile).tableCell}> {(this.state.showWorkerData ? lineData[i].scale_id_detail : lineData[i].scale_id)} </TableCell>

                    {this.state.showWorkerData ? <TableCell align="center" style={classes(theme, isMobile).tableCell}> {lineData[i].worker_id ? lineData[i].worker_id : "None"} </TableCell> : null}
                    {this.state.showWorkerData ? <TableCell align="center" style={classes(theme, isMobile).tableCell}> {lineData[i].worker_name ? lineData[i].worker_name : "Unknown"} </TableCell> : null}

                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].weight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].delta_time).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].kg_per_hour).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].items_underweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].items_overweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].waste).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].waste_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                    <TableCell align="right" style={classes(theme, isMobile).tableCell}> {(lineData[i].first_packed)} </TableCell>
                </TableRow>
        
            elements.push(element)
        }
        
        this.rows = elements;
        this.headings = headingElement
    }

    //generates table headings for mobile
    generateMobileRows() {
        const { data } = this.props;
        const lineData = data.line_data;
        const {showFullLinePath, showWorkerData} = this.state
        let elements = []
        let element

        for (let i = 0; i < lineData.length; i++){
            element = 
                <div style={{marginBottom:"2em"}}>
                    <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: 310}}  size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow key={i} sx={{ backgroundColor: "#065f92" }}>
                                    <TableCell 
                                        style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                        align="center" 
                                        colSpan={2}
                                    > {(showFullLinePath ? lineData[i].line_path : lineData[i].line_id)} Line Details </TableCell>
                                </TableRow>
                            </TableHead>
                
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Scale Details </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].scale_id)} </TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Total Kilograms Packed </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].total_items).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Active Time </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].delta_time).toLocaleString('en-US').replace(",", " ").replace(".", ",")} </TableCell>
                            </TableBody>   
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Average kg/h </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].kg_per_hour).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                align="left"> % Underweight </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].items_underweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> % Overweight </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].items_overweight).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> Total Waste (kg) </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].waste).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody> 
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> % Waste </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].waste_percentage).toLocaleString('en-US').replace(",", " ").replace(".", ",")}</TableCell>
                            </TableBody>
                            <TableBody>
                                <TableCell 
                                    style={{ color: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid", backgroundColor: "#2F83CD" }} 
                                    align="left"> First Packed </TableCell>
                                <TableCell 
                                    style={{ color: "black", backgroundColor: "white", borderWidth: 1, borderColor: "black", borderStyle: "solid" }} 
                                    align="left"> {(lineData[i].first_packed)}</TableCell>
                            </TableBody>           
                        </Table>
                    </TableContainer>
                </div>
            elements.push(element)
        }
    }


    render() {
        const { data } = this.props;

        var lineData

        this.state.showWorkerData ? lineData = data.worker_data : lineData = data.line_data
    
        console.log(this.state.showWorkerData)
        if (lineData){
            this.generateRows()
            this.generateMobileRows()
        }

        if (window.innerWidth < 717) {
            this.state.tableView = true;
        }

        return (
            <div>
                <FormControl
                    component="fieldset"
                    style={classes(theme, isMobile).bulletBox}
                >
                    <Grid container direction="row">
                        <FormControlLabel
                            style = {{marginLeft:"0em"}}
                            label={<span style={{ color:"white" }}> Worker Data </span>}
                            control = {
                                <Switch
                                    sx={{color: blue[800],'&.Mui-checked': {color: blue[600]}}}
                                    checked = {this.showWorkerData}
                                    onChange={(e) => {
                                        this.setState({
                                            showWorkerData: e.target.checked
                                        });
                                    }}
                                    inputProps = {{'aria-label': 'controlled'}} 
                                />
                            }
                        />
                    </Grid>
                </FormControl>
            {/* <FormControlLabel
                control = {<Checkbox
                sx={{color: blue[800], '&.Mui-checked': {color: blue[600]}}}

                checked = {this.state.showFullLinePath}
                onChange={(e) => {
                    this.setState({
                    showFullLinePath: e.target.checked,
                    });
                }}
                inputProps = {{ 'aria-label': 'controlled' }} />}
                label={<span style={{ color:"white" }}> Show Full Line Path </span>} 

            /> */}
            <BrowserView>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                {this.headings}
                </TableHead>
                <TableBody>
                    {this.rows}
                </TableBody>
                </Table>
            </TableContainer>
            </BrowserView>
            <MobileView>
            {this.mobileRows}
            </MobileView>
        </div>
        );
    }
}
export default styled(stationOperatorPerformance)({ theme });
